import React, {useContext} from 'react'
import AutoMenu from './AutoMenu'
import {Link} from 'react-router-dom'
import Banner from './banner'
import LogoBlend from './svg/LogoBlend'
import LogoBar from './svg/LogoBar'
import Stout from './stout'
import Instagram from './icons/Instagram'
import Facebook from './icons/Facebook'
import Twitter from './icons/Twitter'
import './LandingPage.scss'
import Form from './Form'
import './WhatsNew.scss'
import ThemeContext from '../../Context/ThemeContext'



const menuLinks = {
  walnut: [
    {name: "Family Meals TO GO",      link: '/public/walnut-creek/v4-family.pdf'},
    {name: "Meals Instructions",      link: '/public/walnut-creek/bierhaus-family-togo-instructions.pdf'},
    // {name: "Lunch Menu",      link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Lunch%20Menu.pdf'},
    // {name: "Dinner Menu",     link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Dinner%20Menu.pdf'},
    // // {name: "Happy Hour Menu", link: '/public/walnut-creek/v2%20WC%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/walnut-creek/v16%20WC%20Beer%20Menu.pdf'},
    {name: "Beers TO GO",  link: '/public/walnut-creek/v3-Bottle-Menu.pdf'}
  ],
  oakland: [
    // {name: "Daily Menu",  link: '/public/oakland/v6-OAK-Limited-Bierhaus-Food-Menu.pdf'},
    // {name: "Happy Hour Menu",  link: '/public/oakland/L4%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/oakland/v21-OAK-Beer-Menu.pdf'}
  ]
}

const gallery = [
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team.jpg'
  },

  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-1.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-2.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-3.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-4.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-5.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-6.png'
  },

  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team-1.jpg'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team-2.jpg'
  },
]

export default function LandingPage () {
  const {themeName} = useContext(ThemeContext)
  const logoURL = (themeName === 'dark') ?
  "https://afag.imgix.net/tokyoya-sushi/logo-circle.png?w=500&auto=format" :
  "https://afag.imgix.net/tokyoya-sushi/logo-circle.png?w=500&auto=format"
  return(
    <div className="landing-page">
      <div className="header">
        {/* <div className="hero-wrapper">
          <div className="hero-banner">

            <div>
             
              <Link to="/order" className="order-online-button">
              Order Pickup / Delivery
              <br/>
             
            </Link>
             

            </div>
          </div>
          <div className="logo-banner">
            <img src={logoURL}
             alt="Tokyoya"
             className="hero-logo"
           />

          </div>
        </div> */}
        <div className="highlight">
          <p>OPEN ALL DAY, check new hours below</p>
        </div>

        <div className="full-banner">
          <div className="center-logo">
            <img src="https://afag.imgix.net/tokyoya-sushi/logo-block.png?w=2000&auto=format" alt="tokyoya sushi premium omakase & sake bar" />
          </div>
        </div>

       
        
        <div style={{
          textAlign: 'center',
          fontSize: '1.5em',
        }} className="order-link">

        <Link to="/order" className="order-online-button">
              Order Online
              </Link>
        </div>

              
        

      </div>

      <div className="content">

        {/* <p
          style={{
            textAlign: 'center',
            margin: '1.5em 0.5em',
            fontWeight: "600",

          }}
          className="notice">NOTICE: We may have to close early afternoon so get in your order now</p> */}

        <div className="content-grid locations">
          <article className="location walnut">
            <div className="location-header">
              <h2 className="location-name">Los Angeles</h2>

              <p className="subtitle">Burbank</p>

              <p className="address">
                <a className="address-link"
                  href="https://www.google.com/maps/place/Tokyoya+Sushi/@34.1801932,-118.30393,17z/data=!3m1!4b1!4m5!3m4!1s0x80c29533a58e87fd:0x4485655d75494ad3!8m2!3d34.1801932!4d-118.3017413">
                  520 S Glenoaks Blvd
                </a></p>
              <p className="phone">
                 <a
                   style={{fontWeight: 600}}
                   href="tel:+18185627858">818 562 7858</a>
              </p>



              <div className="hours">
                {/* <h3 className="hours-title">Dine-In / Patio / Pickup / Delivery</h3> */}
              
                <p className="week-of-day">Monday to Thursday</p>
<p>11:30am to 9:30pm</p>
<p>(last call: 9:15)</p>

<p className="week-of-day">Friday & Saturday</p>
<p>11:30am to 10pm</p>
<p>(last call: 9:45)</p>


<p className="week-of-day">Sunday</p>
<p>12:00pm - 9:30pm</p>
<p>(last call 9:15)</p>

                {/* <p>dine-in only</p> */}
                {/* <p>Get 15% off your order by joining our mailing list now.</p> */}
                {/* <Link to="/order" className="order-online-button">Order Curbside / Pickup / Delivery</Link> */}

                {/* <p>Sunday Closed</p> */}
              </div>
            </div>


          </article>






        </div>


      </div>

      <div className="whats-new">

       


        {/* <div className="in-the-news">

          <div className="article">
            <img 
            alt="LA Taco: A patriarchy-defying Latina sushi chef, a chef from argentina, and a peruvian taquero brings DTLA's first full Nikkei restaurant"
            src="https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/la-tacos-review.png?w=800&auto=format" alt=""/>
            <a href="https://www.lataco.com/nikkei-dtla-japanese-peruvian/">read review</a>
          </div>

        </div> */}

        


        <Form></Form>

{/* 
        <div className="gallery">
          {gallery.map(({url, alt}) => {
            return (
              <img
               alt="sample food photo of Mikaza, menu coming later"
               className={"gallery-img"}
               src={`${url}?fit=crop&w=400&h=400`} alt=""/>
            )
          })}
        </div> */}

{/* 
        <div className="whats-new__content">

          <article className="full-spread slow cooked">

            <div className="main">
              <img src="https://afag.imgix.net/bierhaus/ribs-close-up.jpg?w=1200&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Slow-Cooked</h2>
                <p>Available warm or as reheat-at-home meal kits.</p>
              </div>
            </div>

          </article>



        </div> */}
{/*
        <div className="link-wrapper">
          <a href="/order?bottom" className="order-online-button">View Full Menu</a>
        </div> */}

      </div>

      <div className="menu">
        {/* <div className="menu-header">
          <h2>Our Menu</h2>
        </div> */}

        <AutoMenu width={600}></AutoMenu>

        {/* <div className="beverage-menu-link-wrapper">
          <Link to="/drinks" className="order-online-button">Full Beverage Menu</Link>
        </div> */}
      </div>



      {/* <div className="content">
        <div className="content-grid">
          <div className="email-form">
            <EmailSubscribeForm></EmailSubscribeForm>
          </div>


          <div className="social-links">
            <a
              className="social-link"
              href="https://www.instagram.com/bierhausca/"><Instagram></Instagram>Instagram</a>
            <a
              className="social-link"
              href="https://www.facebook.com/bierhausca/"><Facebook></Facebook>Facebook</a>
            <a
              className="social-link"
              href="https://twitter.com/BierhausCA"><Twitter></Twitter>Twitter</a>
          </div>
        </div>
      </div> */}






      {/* <div className="landing-page-footer">
        <Stout></Stout>
      </div> */}
    </div>
  )
}
