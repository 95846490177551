
import * as Components from "./Tokyoya"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "tokyoya"
}

